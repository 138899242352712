<template>
  <div id="app">
    <MainApp></MainApp>
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import '@/assets/css/color.css';

import MainApp from '@/MainApp.vue'

export default {
  name: 'App',
  data() {
    return {
    }
  },
  components: {
    MainApp
  },
  mounted() {

  },
  computed: {

  },
  methods: {

  }
}
</script>

<style scoped>
  #app {
    /* background-color: #ddd; */
  }
</style>
