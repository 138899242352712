<template>
  <nav>
    <img :src="require('@/assets/images/banner_bg.png')" class="banner-bg"/>

    <div class="front">
      <div class="info">
        <h1>2023 泳渡日月潭</h1>
        <div class="colabos">
          <div class="colabo">
            <label>執行單位</label>
            <div class="imgs">
              <img class="colab-1" :src="require('@/assets/images/colab-1.png')" />
            </div>
          </div>

          <div class="colabo">
            <label>協辦單位</label>
            <div class="imgs">
              <img class="colab-2" :src="require('@/assets/images/colab-2.png')" />
              <img class="colab-3" :src="require('@/assets/images/colab-3.png')" />
            </div>
          </div>
        </div>
      </div>

      <div class="logo-and-btns">
        <div class="tool-div">
          <p class="status-text">
            <span>活動狀態：</span>
            <span>{{ statusText }}</span>
          </p>
          <div class="second-line">
            <p>{{ gameLengthHour }}:{{ gameLengthMinute }}:{{ gameLengthSecond }}</p>
            <button class="orange-btn" v-if="!hasRunningGame" @click="runNewGame">活動開始</button>
            <button class="orange-btn" v-if="hasRunningGame" @click="endGame">活動結束</button>
          </div>
          <ul>
            <li>
              <RouterLink :to="{name:'Dashboard'}">參賽資料</RouterLink>
            </li>
            <li>
              <RouterLink :to="{name:'SwimMap'}">位置地圖</RouterLink>
            </li>
            <li>
              <RouterLink :to="{name:'MobileTestDevice'}">測試區</RouterLink>
            </li>
            <li>
              <RouterLink :to="{name:'SwimRace'}">活動設定</RouterLink>
            </li>
          </ul>
        </div>

        <img :src="require('@/assets/images/banner-logo.png')" class="logo" />
      </div>
    </div>
  </nav>
</template>

<script>
// import axios from 'axios';
import moment from 'moment';
import { RouterLink } from 'vue-router';

// @ is an alias to /src

export default {
  name: 'SwimNav',
  data() {
    return {
      runningGames: [],
      disSecondCount: -1,
      timer: null,
    };
  },
  computed: {
    runningGame() {
      if (this.runningGames.length > 0) {
        return this.runningGames[0];
      }
      return null;
    },
    hasRunningGame() {
      return this.runningGame !== null;
    },
    statusText() {
      return this.hasRunningGame? '開始': '準備中';
    },
    gameLengthSecond() {
      if (!this.hasRunningGame) {
        return '00';
      }
      return (Math.floor(this.disSecondCount % 60)).toString().padStart(2, '0');
    },
    gameLengthMinute() {
      if (!this.hasRunningGame) {
        return '00';
      }
      return (Math.floor((this.disSecondCount / 60) % 60)).toString().padStart(2, '0');
    },
    gameLengthHour() {
      if (!this.hasRunningGame) {
        return '00';
      }
      return (Math.floor(this.disSecondCount / 3600)).toString().padStart(2, '0');
    },
    startGameTime() {
      if (this.hasRunningGame) {
        return moment(this.runningGame.StartTime).format('YYYY-MM-DD HH:mm:ss');
      }
      return '';
    }
  },
  components: {
    RouterLink
  },
  async mounted() {
    await this.readRunning();
  },
  methods: {
    asyncGameStatusToStore() {
      if (this.$store.getters.isGameRunning === this.hasRunningGame) {
        return;
      }
      if (this.hasRunningGame) {
        this.$store.dispatch('startGame', this.runningGame.StartTime);
      } else {
        this.$store.dispatch('endGame');
      }
    },
    async readRunning() {
      const BUSYNAME = 'READGAMEBUSY';
      this.$store.dispatch('appendComponentBusy', BUSYNAME);
      try {
        const runningGameList = await this.$store.dispatch('api/readRunningGameListPromise');
        this.runningGames.splice(0, this.runningGames.length);
        for (let i=0;i<runningGameList.length;i++) {
          this.runningGames.push(runningGameList[i]);
        }
        this.resetTimer();
        this.asyncGameStatusToStore();
      } catch (error) {
        alert(error.toString());
      } finally {
        this.$store.dispatch('clearComponentBusy', BUSYNAME);
      }
    },
    async runNewGame() {
      const BUSYNAME = 'RUNNEWGAMEBUSY';
      this.$store.dispatch('appendComponentBusy', BUSYNAME);
      try {
        const newGameName = `NewGame_${moment().format('YYYY-MM-DD_HH:mm:ss')}`;
        const newGame = await this.$store.dispatch('api/createGamePromise', {
          GameName: newGameName
        });
        const startedNewGame = await this.$store.dispatch('api/startGamePromise', newGame.serial);
        this.runningGames.unshift(startedNewGame);
        await this.readRunning();
        this.resetTimer();
        this.asyncGameStatusToStore();
      } catch (error) {
        alert(error.toString());
      } finally {
        this.$store.dispatch('clearComponentBusy', BUSYNAME);
      }
    },
    async endGame() {
      const BUSYNAME = 'ENDGAMEBUSY';
      this.$store.dispatch('appendComponentBusy', BUSYNAME);
      try {
        await this.$store.dispatch('api/finishGamePromise', this.runningGame.serial);
        await this.readRunning();
      } catch (error) {
        alert(error.toString());
      } finally {
        this.$store.dispatch('clearComponentBusy', BUSYNAME);
      }
    },
    resetTimer() {
      if (this.timer !== null) {
        clearInterval(this.timer);
        this.timer = null;
      }
      if (this.hasRunningGame) {
        this.disSecondCount = parseInt(new Date().getTime() - this.runningGame.StartTime) / 1000;
        this.timer = setInterval(() => {
          this.disSecondCount = parseInt(new Date().getTime() - this.runningGame.StartTime) / 1000;
          // this.disSecondCount += 1;
          // console.log('set');
        }, 1000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  
  nav {
    // background-color: #B7DED8;
    // padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    flex: 0 0 auto;
    align-items: center;
    position: relative;

    

    .banner-bg {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      z-index: -1;
    }

    .front {
      // position: absolute;
      width: 100%;
      // height: 100%;
      // top: 0;
      // left: 0;

      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 3.88rem;

      @media screen and (max-width: 1200px) {
        flex-wrap: wrap;
      }

      .info {
        flex: 0 0 auto;

        color: rgba(255, 255, 255, 0.80);

        @media screen and (max-width: 1200px) {
          display: none;
        }
        h1 {
          font-size: 4rem;
          font-weight: 600;
          line-height: 150%; /* 6rem */
          letter-spacing: 0.4rem;
          margin: 0;
          
        }

        .colabos {
          display: flex;
          flex-direction: row;
          column-gap: 1.75rem;
          .colabo {
            label {
              font-size: 0.8125rem;
              font-style: normal;
              font-weight: 600;
              line-height: 150%; /* 1.21875rem */
              letter-spacing: 0.08125rem;
              margin-bottom: .5rem;
            }

            .imgs {
              display: flex;
              align-items: center;
              height: 2rem;
              img {
                display: block;
                &:not(:first-child) {
                  margin-left: 1.5rem;
                }

                &.colab-1 {
                  height: 2rem;
                }
                &.colab-2 {
                  height: 1.25rem;
                }
                &.colab-3 {
                  height: .5rem;
                }
              }
            }
          }
        }
      }

      .logo-and-btns {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        column-gap: 1.88rem;
        .tool-div {
          flex: 0 0 auto;
          color: #fff;

          .status-text {
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 600;
            line-height: 150%; /* 1.875rem */
            letter-spacing: 0.125rem;

            margin-bottom: .25rem;
            span:first-child {
              opacity: .6;
              display: inline-block;
              padding-right: .75rem;
            }
            span:last-child {
            }
          }
          .second-line {
            display: flex;
            align-items: center;
            p {
              color: #FFF;
              font-size: 2rem;
              font-style: normal;
              font-weight: 500;
              line-height: 150%; /* 3rem */
              letter-spacing: 0.2rem;
              margin-bottom: 0;
              margin-right: 1rem;
              font-family: math;
            }

            button.orange-btn {
              border-radius: 1.25rem;
              background: var(--main-orange);
              border: none;
              padding: .25rem 1rem;
              color: #fff;
              font-size: 1rem;
              font-style: normal;
              font-weight: 500;
              line-height: 150%; /* 1.5rem */
              letter-spacing: 0.1rem;
              transition: background-color .4s ease;
              &:hover {
                background-color: var(--dark-orange);
              }
            }
          }

          ul {
            margin: 0;
            margin-top: .5rem;
            padding: 0;
            display: flex;
            column-gap: 1.5rem;
            li {
              display: block;
              list-style: none;
              margin: 0;
              padding: 0;
              a {
                color: #fff;
                transition: opacity .4s ease;
                opacity: .6;
                text-decoration: underline;
                &:hover {
                  opacity: 1;
                }
              }
            }
          }
        }

        img.logo {
          display: block;
          width: 14.6875rem;
          @media screen and (max-width: 1200px) {
            display: none;
          }
        }
      }
    }
    // .logo {
    //   flex: 1 1;
    //   display: flex;
    //   align-items: center;
    //   @media screen and (max-width: 768px) {
    //     flex: 0 0 100%;
    //     flex-wrap: wrap;
    //   }
    //   img {
    //     display: block;
    //     width: 2.5rem;
    //     height: 2.5rem;
    //   }

    //   .status {
    //     opacity: .6;
    //     padding: 0 1.8rem;
    //   }

    //   .btn {
    //     border-radius: 999px;
    //     color: #fff;
    //     &.btn-orange {
    //       background-color: var(--light-orange);
    //       &:hover {
    //         background-color: var(--dark-orange);
    //       }
    //     }

    //     &.btn-grey {
    //       background-color: #999999;
    //       &:hover {
    //         background-color: #444;
    //       }
    //     }
    //   }
    // }
  }

  .cus-nav-links {
    flex: 0 0 auto;
    @media screen and (max-width: 768px) {
      padding: .5rem 0;
    }
    .cus-nav-link {
      padding: 0 2rem;
      @media screen and (max-width: 768px) {
        padding: .5rem 1rem;
      }
    }
  }
  

</style>
