import axios from 'axios';

export default {
  namespaced: true,
  state: {
    URLS: {
      QUERYDEVICERECORDS: '/api/deviceRecord/device',
      READRECORDBYGAME: '/api/deviceRecord/listByGame',

      CREATEUSER: '/api/user/create',
      UPDATEUSER: '/api/user/update',
      DELETEUSER: '/api/user/delete',
      READUSERLIST: '/api/user/list',
      SEARCHUSER: '/api/user/search',

      READGAMELIST: '/api/game/list',
      READRUNNINGGAMES: '/api/game/runningList',
      CREATEGANE: '/api/game/create',
      STARTGAME: '/api/game/start',
      FINISHGAME: '/api/game/finish',
      UPDATEGAME: '/api/game/update',

      RECORDTESTGPS: '/api/deviceRecord/save/test',
    }
  },
  getters: {
    axiosConfig() {
      return {
        headers: {
          'Cache-Control': 'no-cache',
        }
      };
    },
  },
  mutations: {

  },
  actions: {
    postApiPromise({getters}, {url, data}) {
      return new Promise(function(resolve, reject) {
        const postData = data? data: {};
        axios.post(url, postData, getters.axiosConfig).then(function(d) {
          if (d.status === 200 && d.data.status === 'ok') {
            resolve(d.data.data);
          } else {
            reject(d.data.reason);
          }
        }).catch(function(e) {
          reject(e);
        });
      });
    },
    getApiPromise({getters}, {url}) {
      return new Promise(function(resolve, reject) {
        axios.get(url, getters.axiosConfig).then(function(d) {
          if (d.status === 200 && d.data.status === 'ok') {
            resolve(d.data.data);
          } else {
            reject(d.data.reason);
          }
        }).catch(function(e) {
          reject(e);
        });
      });
    },

    createUserPromise({dispatch, state}, user) {
      return dispatch('postApiPromise', {
        url: `${state.URLS.CREATEUSER}`,
        data: user,
      });
    },

    updateUserPromise({dispatch, state}, user) {
      return dispatch('postApiPromise', {
        url: `${state.URLS.UPDATEUSER}`,
        data: user,
      });
    },

    deleteUserPromise({dispatch, state}, serial) {
      return dispatch('postApiPromise', {
        url: `${state.URLS.DELETEUSER}`,
        data: {
          serial
        },
      });
    },
    
    readUserListPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: `${state.URLS.READUSERLIST}`,
      });
    },

    searchUserPromise({dispatch, state}, query) {
      return dispatch('postApiPromise', {
        url: `${state.URLS.SEARCHUSER}`,
        data: {
          q: query,
        },
      });
    },

    readGameListPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: `${state.URLS.READGAMELIST}`,
      });
    },

    readRunningGameListPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: `${state.URLS.READRUNNINGGAMES}`,
      });
    },

    createGamePromise({dispatch, state}, game) {
      return dispatch('postApiPromise', {
        url: `${state.URLS.CREATEGANE}`,
        data: game,
      });
    },

    startGamePromise({dispatch, state}, serial) {
      return dispatch('postApiPromise', {
        url: `${state.URLS.STARTGAME}`,
        data: {
          serial,
        },
      });
    },

    finishGamePromise({dispatch, state}, serial) {
      return dispatch('postApiPromise', {
        url: `${state.URLS.FINISHGAME}`,
        data: {
          serial,
        },
      });
    },

    updateGamePromise({dispatch, state}, data) {
      return dispatch('postApiPromise', {
        url: `${state.URLS.UPDATEGAME}`,
        data,
      });
    },
    
    saveTestPromise({dispatch, state}, data) {
      return dispatch('postApiPromise', {
        url: `${state.URLS.RECORDTESTGPS}`,
        data,
      });
    },

    searchRecordsByDeviceIdPromise({dispatch, state}, {deviceId, start, end}) {
      const q = [];
      if (start !== undefined) {
        q.push({
          key: 'start',
          value: start,
        });
      }
      if (end !== undefined) {
        q.push({
          key: 'end',
          value: end,
        });
      }
      let queryStr = q.map((item) => {
        return `${item.key}=${item.value}`;
      }).join('&');
      return dispatch('getApiPromise', {
        url: `${state.URLS.QUERYDEVICERECORDS}/${deviceId}?${queryStr}`,
      });
    },

    readRecordByGamePromise({dispatch, state}, gameId) {
      return dispatch('getApiPromise', {
        url: `${state.URLS.READRECORDBYGAME}/${gameId}`,
      });
    },
    
  },

}
