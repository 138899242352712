import Vue from 'vue';
import Vuex from 'vuex';

import ApiModule from './modules/api.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    storeBusy: [],
    errMsgs: [],
    
    gameRunning: false,
    gameStartTime: 0,
  },
  getters: {
    isStoreBusy(state) {
      return state.storeBusy.length > 0;
    },
    isGameRunning(state) {
      return state.gameRunning;
    },
    runningGameStartTime(state) {
      return state.gameRunning? state.gameStartTime: 0;
    },
  },
  mutations: {
    APPENDBUSY(state, payload) {
      const index = state.storeBusy.indexOf(payload);
      if (index === -1) {
        state.storeBusy.push(payload);
      }
    },
    CLEARBUSY(state, payload) {
      const index = state.storeBusy.indexOf(payload);
      if (index !== -1) {
        state.storeBusy.splice(index, 1);
      }
    },
    APPENDERRORMSG(state, errMsg) {
      state.errMsgs.push(errMsg);
    },
    CLEARERRORMSG(state) {
      state.errMsgs.splice(0, state.errMsgs.length);
    },
    SETGAMERUNNING(state, gameRunning) {
      state.gameRunning = gameRunning;
    },
    SETGAMESTARTTIME(state, startTime) {
      state.gameStartTime = startTime;
    }
  },
  actions: {
    appendComponentBusy(context, busyName) {
      context.commit("APPENDBUSY", busyName);
    },
    clearComponentBusy(context, busyName) {
      context.commit("CLEARBUSY", busyName);
    },
    appendErrorMsg(context, errMsg) {
      context.commit("APPENDERRORMSG", errMsg);
    },
    startGame(context, time) {
      if (context.getters.isGameRunning) {
        throw new Error('活動已進行中');
      }
      context.commit("SETGAMERUNNING", true);
      context.commit("SETGAMESTARTTIME", time);
    },
    endGame(context) {
      if (!context.getters.isGameRunning) {
        throw new Error('沒有進行中的活動');
      }
      context.commit("SETGAMERUNNING", false);
    },
  },
  modules: {
    api: ApiModule,
  },
});
