<template>
  <div class="main-wrap">

    <div class="main-content">
      <router-view ref="main-component"/>
    </div>

    <SwimNav></SwimNav>

    <Transition>
      <div class="fade-busy-window" v-if="isBusy">

      </div>
    </Transition>
    
  </div>
</template>

<script>
import Vue from 'vue';
import { BootstrapVue } from 'bootstrap-vue';
import SwimNav from '@/views/SwimNav.vue';

import moment from 'moment';
moment.locale('zh-tw');

Vue.use(BootstrapVue);

export default {
  name: 'MainApp',
  data() {
    return {
    };
  },
  props: {
  },
  components: {
    SwimNav,
  },
  async mounted() {

  },
  computed: {
    isBusy() {
      return this.$store.getters.isStoreBusy;
    }
  },
  watch: {
    async $route(to, from) {
      console.log(`from: ${from.name}, to: ${to.name}`);
    },
  },
  methods: {
    
  }
}
</script>


<style>
:root {
}
</style>

<style scoped>
  .main-wrap {
    width: 100%;
    height: 100vh;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .main-content {
    position: relative;
    flex: 1 1;
    background-color: #D7EBE8;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .fade-busy-window {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #0004;
    z-index: 10000;
  }
</style>
