import Vue from 'vue';
import VueRouter from 'vue-router';
// import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    // name: 'Home',
    // component: Home,
    redirect: { name: 'Dashboard' }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "Dashboard" */ '../views/Dashboard.vue')
  },
  {
    path: '/map',
    name: 'SwimMap',
    component: () => import(/* webpackChunkName: "SwimMap" */ '../views/SwimMap.vue')
  },
  {
    path: '/test',
    name: 'MobileTestDevice',
    component: () => import(/* webpackChunkName: "MobileTestDevice" */ '../views/MobileTestDevice.vue')
  },
  {
    path: '/race',
    name: 'SwimRace',
    component: () => import(/* webpackChunkName: "SwimRace" */ '../views/SwimRace.vue')
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
